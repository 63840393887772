<template>
  <div v-show="dataLoaded">
    <v-row class="my-0">
      <v-col
        cols="12"
        class="py-0"
      >
        <v-card class="table-card">
          <v-data-table
            ref="pairTimes"
            class="base-table"
            fixed-header
            hide-default-footer
            :headers="headers"
            :items="valuesArray"
            :items-per-page="-1"
          >
            <template slot="no-data">
              <div>Нет данных</div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { catalogApi } from '@/api'
import { mapGetters } from 'vuex'

export default {
  name: 'PairTimeTable',

  async created() {
    await this.getPairTimes()
    this.dataLoaded = true
    this.$emit('dataLoaded')
  },

  mounted() {
    this.setTableHeight()
    window.addEventListener('resize', this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener('resize', this.setTableHeight)
  },

  data: () => ({
    dataLoaded: false,
    headers: [
      { text: 'Расписание занятий', value: 'pairTime', sortable: false, align: 'left' },
    ],

    pairTimes: []
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('department', ['departmentId']),

    valuesArray() {
      return this.pairTimes.map(
        el => ({ ...el, pairTime: `${el.beginTime} - ${el.endTime}` }))
    }
  },

  methods: {
    async getPairTimes() {
      try {
        this.pairTimes = await catalogApi.getPairTime()
      } catch (e) {
        console.log(e.status)
      }
    },

    setTableHeight() {
      const otherElements = 261 + this.techMessageHeight

      if (this.$refs?.pairTime) {
        this.$refs.pairTime.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    }
  }
}
</script>