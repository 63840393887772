<template>
  <div>
    <div class="text-center" v-if="!departmentDataLoaded || !dataLoaded">
      <v-progress-circular class="mt-6" :size="50" color="primary" indeterminate />
    </div>

    <div class="mx-4" v-if="departmentDataLoaded">
      <div v-show="dataLoaded">
        <PageTitle title="Время проведения занятий" />

        <v-row class="my-0">
          <v-col cols="12" class="pt-0 pb-5">
            <h4 class="department">{{ departmentName }}</h4>
          </v-col>
        </v-row>

        <PairTime
          class="mini-table"
          @dataLoaded="dataLoaded = true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageTitle from '@/components/ui/Title'
import PairTime from '@/components/department/timeManagement/tables/PairTime.vue'

export default {
  name: 'TimeManagement',

  metaInfo: {
    title: 'Время проведения занятий'
  },

  components: {
    PageTitle,
    PairTime
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    this.departmentDataLoaded = true
  },

  mounted() {
    this.hideHtmlOverflow()
  },

  destroyed() {
    this.showHtmlOverflow()
  },

  data: () => ({
    departmentDataLoaded: false,
    dataLoaded: false
  }),

  computed: {
    ...mapGetters('department', ['departmentName']),
  },

  methods: {
    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>

<style scoped>
.tables-card {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  background-color: var(--background-color) !important;
}

.mini-table {
  min-width: calc(100% / 3 - 10px);
  max-width: calc(100% / 3 - 10px);
}

.mini-table:last-child {
  margin-right: 0
}
</style>